import './OrderList.css';

import { useEffect, useState } from 'react';

// Firebase
import { db } from '../Firebase';
import { doc, getDoc } from 'firebase/firestore';

// Functions
import { renderPrice, renderPortion } from '../Functions';

// NPM
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';

const OrderList = () => {

    const [orderList, setOrderList] = useState([]);

    // Get orderlist and portiontypes from firebase
    useEffect(() => {
        getOrderList();
    },[])

    const getOrderList = async () => {
        const listRef = doc(db, 'orderList', 'listJuly22');
        const listSnap = await getDoc(listRef);
        if(listSnap.exists()) {
            const obj = {...listSnap.data(), id: listSnap.id};
            setOrderList(obj.orderList);
        } else {
            console.log('no such document');
        }
    }

    // Generate PDF from orderList items
    const downloadPdfDocument = () => {

        var doc = new jsPDF('p', 'pt', 'letter');
        var pageHeight = 0;
        var width = doc.internal.pageSize.getWidth()
        pageHeight = doc.internal.pageSize.height;

        doc.setLineWidth(2);
        doc.text('Smaack bestellijst 2024', width/2, 40, { align: 'center' })
        doc.autoTable({
            html: '#orderList',
            startY: 70,
            theme: 'grid',
            columnStyles: {
                0: {
                    cellWidth: 20,
                },
            },
            styles: {
                minCellHeight: 20
            }
        })
        doc.save('smaack_bestellijst_2024.pdf');
    }

    return(
        <div className="order-list">
            <h1 className='title'>Bestellijst 2024</h1>
            <table id="orderList">
                <tbody>
                    {
                        orderList.map((item) => {
                            return (
                                <tr>
                                    <td className='checkbox-container'><div className='checkbox'/></td>
                                    <td>{item.title} {item.description && <small><i>({item.description})</i></small>}</td>
                                    <td>{renderPortion(item.amount, item.portionType)}</td>
                                    <td>{renderPrice(item.price)}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
            <button onClick={() => {downloadPdfDocument()}}>Download PDF</button>
        </div>
    );
}

export default OrderList;